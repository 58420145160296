import $ from 'jquery'
import { showModal } from '~/common/helpers/modal'
import scrollIntoView from 'scroll-into-view-if-needed'
import { replaceWithPreserves } from '../helpers/replace'
import { extractModals } from '../helpers/modal'
import { getIframeInParentDoc } from '../helpers/iframe'

$.extend($.ajaxSettings, {
  async: true,
  dataType: 'json',
  type: 'POST',
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
})
// X-Requested-With doesn't get set automatically for cross-domain requests for some reason
// Don't know who's normally responsible for setting it, but this works
// http://stackoverflow.com/questions/23610014/request-xhr-cors-layout-fail

$(document).on('ajax:success', '[data-update]', function (event, data) {
  const $this = $(this)
  const target = $this.data('update')
  const effect = $this.data('effect')
  $('#' + target)
    .html(data[target])
    .changed()
  if (effect) {
    $('#' + target).effect(effect, {}, 5000)
  }
})

$(document).on('ajax:success', '[data-replace]', function (event, data) {
  const $this = $(this)
  const target = $this.data('replace')
  const effect = $this.data('effect')
  $('#' + target).replaceWith(data[target])
  $('#' + target).changed()
  if (effect) {
    $('#' + target).effect(effect, {}, 5000)
  }
})

$(document).on('ajax:success', '[data-remove]', function () {
  const $this = $(this)
  const effect = $this.data('effect')
  const target = $('#' + $this.data('remove'))
  if (effect != null) {
    target.effect(effect, {}, 500, () => target.remove())
  } else {
    target.remove()
  }
})

const idify = function (selector) {
  if (
    selector[0] === '.' ||
    selector[0] === '#' ||
    selector[0] === '[' ||
    selector === 'head'
  ) {
    return selector
  } else {
    return '#' + selector
  }
}

const focusFirstInput = function (selector) {
  const firstInput = selector.find('input:visible:first')

  if (
    firstInput.length > 0 &&
    firstInput.parents('.js-disable-ajax-autofocus').length == 0
  ) {
    firstInput.focus()
  }
}

const each_selector = function (object, callback) {
  if (!object) return
  for (let selector in object) {
    const value = object[selector]
    callback.call($(idify(selector)), value)
  }
}

const showNoticeWithFireworks = function (data) {
  const notify = () => window.showNotice('notice', data.notice)
  if (data.celebrate != null) {
    window.Simplero.celebrate(data.celebrate, notify)
  } else {
    notify()
  }
}

export const AjaxHelper = {
  doUpdate(data, eventTarget) {
    $('[rel=popover]').popover?.('hide')

    // Remove first, so we don't remove something we've just inserted

    if (data.hide != null) {
      for (const id of Array.from(data.hide)) {
        $(idify(id)).hide()
      }
    }
    if (data.remove != null) {
      for (const id of Array.from(data.remove)) {
        $(idify(id)).remove()
      }
    }

    if (data.clear_local_storage) {
      for (let storage_key of Array.from(data.clear_local_storage)) {
        window.LocalDraftManager.clearLocalStorage(storage_key)
      }
    }

    if (data.hide_clickover) {
      $('[data-clickover-open=1]').each(function () {
        $(this).data('clickover') && $(this).data('clickover').clickery()
      })
    }

    if (data.fade != null) {
      for (const id of Array.from(data.fade)) {
        $('#' + id).effect('fade', {}, 500, function () {
          const parent = $(this).parent()
          $(this).remove()
          parent.changed()
        })
      }
    }

    // Always hide these
    $('.tooltip,.clickover').remove()

    // Flag the window as performing AJAX updates.
    window.Simplero.state.isAjaxUpdating = true

    if (data.refresh_table != null) {
      for (const id of Array.from(data.refresh_table)) {
        $(idify(id)).data('simpleroManagerCustomView')?.refreshTable()
      }
    }

    if (data.refresh_calendar != null) {
      document.querySelectorAll('[data-controller*=calendar]').forEach((el) =>
        el.dispatchEvent(
          new CustomEvent('refresh-calendar', {
            bubbles: true,
            cancelable: true,
          })
        )
      )
    }

    if (data.update_calendar_events != null) {
      document.querySelectorAll('[data-controller*=calendar]').forEach((el) =>
        el.dispatchEvent(
          new CustomEvent('update-calendar-events', {
            bubbles: true,
            cancelable: true,
            detail: {
              events: data.update_calendar_events,
            },
          })
        )
      )
    }

    if (data.invalidate_queries != null) {
      for (let query of Array.from(data.invalidate_queries)) {
        // Need this on the webpack side as well, triggering via jquery would requires us to use jquery
        const event = new CustomEvent('simplero:invalidate-query', {
          bubbles: true,
          cancelable: true,
          detail: { query },
        })
        document.dispatchEvent(event)
      }
    }

    let html, elm, doc
    if (data.update != null) {
      for (const id in data.update) {
        html = data.update[id]
        elm = $(idify(id))
        if (elm.is('iframe')) {
          doc = elm[0].contentWindow.document
          doc.open()
          doc.write(html)
          doc.close()
        } else {
          elm.html(html).changed()
        }
      }
    }

    if (data.update_selector != null) {
      for (const selector in data.update_selector) {
        html = data.update_selector[selector]
        elm = $(selector)
        if (elm.is('iframe')) {
          doc = elm[0].contentWindow.document
          doc.open()
          doc.write(html)
          doc.close()
        } else {
          elm.html(html).changed()
        }
      }
    }

    // Hash of id => html
    if (data.replace != null) {
      for (const id in data.replace) {
        html = data.replace[id]

        const selector = idify(id)
        replaceWithPreserves($(selector), html)
        extractModals($(selector)[0])
      }
    }

    // Hash of id => html
    // Similar to replace, exxcept it will query the html for the selector and only replace it with that
    if (data.extract_and_replace != null) {
      for (const id in data.extract_and_replace) {
        const selector = idify(id)
        html = $(data.extract_and_replace[id]).find(selector)
        $(selector).replaceWith(html)
        $(selector).changed()
      }
    }

    // Hash of id => html
    if (data.append != null) {
      for (const id in data.append) {
        html = data.append[id]
        $(idify(id)).append(html)
        focusFirstInput($(idify(id)).changed())
      }
    }

    // Hash of id => html
    if (data.prepend != null) {
      for (const id in data.prepend) {
        html = data.prepend[id]
        $(idify(id)).prepend(html)
        focusFirstInput($(idify(id)).changed())
      }
    }

    if (data.insert_after != null) {
      for (const id in data.insert_after) {
        html = data.insert_after[id]
        const $el = $(idify(id)).after(html).next().changed()
        if (html) {
          focusFirstInput($el)
        }
      }
    }

    if (data.insert_before != null) {
      for (const id in data.insert_before) {
        html = data.insert_before[id]
        focusFirstInput($(idify(id)).before(html).prev().changed())
      }
    }

    if (data.enable != null) {
      for (const selector of Array.from(data.enable)) {
        $(idify(selector)).removeClass('disabled').attr('disabled', false)
      }
    }

    if (data.fire_gtm_event != null && typeof fireGTMEvent !== 'undefined') {
      window.fireGTMEvent(data.fire_gtm_event)
    }

    each_selector(data.remove_class, function (klass) {
      this.removeClass(klass)
    })
    each_selector(data.add_class, function (klass) {
      this.addClass(klass)
    })

    each_selector(data.update_attribute, function (updates) {
      for (let attribute in updates) {
        this.attr(attribute, updates[attribute])
      }
    })

    each_selector(data.clear_inline_style, function (styleKey) {
      this.css(styleKey, '')
    })

    if (data.error != null && data.error.replace(/^\s+|\s+$/g, '') !== '') {
      alert(data.error)
    }

    if (data.focus_selector != null) {
      $(data.focus_selector).focus()
    }

    if (data.errors_on != null) {
      for (const id in data.errors_on) {
        const message = data.errors_on[id]
        const input = $(idify(id))
        const formgroup = input.closest('.form-group')
        formgroup
          .removeClass('has-error')
          .find('.form-control-feedback, .help-block')
          .remove()
        if (message != null && message !== '') {
          formgroup
            .addClass('has-error')
            .append($('<p></p>').addClass('help-block').html(message))
        }
      }
    }

    if (data.editor_insert) {
      window.tinyMCE.activeEditor?.insertContent(data.editor_insert)
      window.prosemirror_editor_insert_callback?.(data.editor_insert)
    }

    if (data.saved != null) {
      $('form.dirty').removeClass('dirty')
    }

    if (data.copy_to_clipboard != null) {
      navigator.clipboard.writeText(data.copy_to_clipboard)
    }

    if (data.changed != null) {
      for (const id of Array.from(data.changed)) {
        $('#' + id).changed()
      }
    }

    if (data.side_panel != null) {
      let detail = data.side_panel
      if (typeof detail !== 'object') {
        detail = { content: data.side_panel }
      }

      document
        .getElementById('side_panel')
        .dispatchEvent(new CustomEvent('show', { detail }))
    }

    if (data.hide_side_panel != null) {
      document
        .getElementById('side_panel')
        .dispatchEvent(new CustomEvent('hide'))
    }

    if (data.push_pane != null) {
      let content = data.push_pane.content || data.push_pane
      let target =
        !!data.push_pane.selector &&
        document.querySelector(idify(data.push_pane.selector))
      target = target || $(eventTarget).data('portal') || eventTarget

      target.dispatchEvent(
        new CustomEvent('panes:push', {
          detail: { content },
          bubbles: true,
        })
      )
    }
    if (data.replace_pane != null) {
      const target = $(eventTarget).data('portal') || eventTarget
      const detail =
        typeof data.replace_pane == 'string'
          ? { content: data.replace_pane }
          : data.replace_pane
      target.dispatchEvent(
        new CustomEvent('panes:replace', {
          detail,
          bubbles: true,
        })
      )
    }
    if (data.pop_pane != null) {
      const target = $(eventTarget).data('portal') || eventTarget
      target?.dispatchEvent(new CustomEvent('panes:pop', { bubbles: true }))
    }
    if (data.set_panes != null) {
      const target = $(eventTarget).data('portal') || eventTarget
      target.dispatchEvent(
        new CustomEvent('panes:set', {
          detail: { content: data.set_panes },
          bubbles: true,
        })
      )
    }
    if (data.object_picker_pick) {
      const target = $(eventTarget).data('portal') || eventTarget
      target.dispatchEvent(
        new CustomEvent('object-picker:pickFromJSEvent', {
          detail: data.object_picker_pick,
          bubbles: true,
        })
      )
    }

    // Flag the window as no long performing AJAX updates.
    window.Simplero.state.isAjaxUpdating = false

    if (data.alert != null) {
      window.showNotice('alert', data.alert)
    }
    if (data.notice != null && !data.redirect) {
      showNoticeWithFireworks(data)
    }

    if (data.show != null) {
      for (const id of Array.from(data.show)) {
        $(idify(id)).show()
      }
    }

    // Will trigger show unless the selector condition returns some element
    // An example use case would be where you want to show the elemnet using ajax, but only if some other element does not exist/is invisible/etc
    if (data.show_unless != null) {
      for (let object of Array.from(data.show_unless)) {
        for (let condition in object) {
          const id = object[condition]
          if ($(condition).length === 0) {
            $('#' + id).show()
          }
        }
      }
    }
    if (data.hide_and_submit_payment) {
      delete data.entrant.id
      $('#entrants input[name="signed_agreement_entrant"]')
      $('#entrants input[name="signed_agreement_entrant"]').val(
        JSON.stringify(data.entrant)
      )
      $(`#${data.hide_agreement_modal_id}`).modal('hide')
      $(`#${data.submit_purchase_form_id}`).submit()
    }

    if (data.hide_modal) {
      if (typeof data.hide_modal === 'boolean') {
        $('#modal-container .modal').modal('hide')
      } else {
        const el = document.querySelector(`#${data.hide_modal}`)
        if (el) {
          if (el.modal) {
            el.modal('hide')
          } else {
            $(el).modal('hide')
          }
        }
      }
    }

    if (data.clear_modal) {
      $('#modal-container .modal input[type=text]').val('')
    }
    if (data.clear_form) {
      $(data.clear_form).find('input[type=text]').val('')
    }

    if (data.reset_form) {
      // More standard way of doing things than clear_form above - handles more things than just text inputs (e.g tiptap wysiwyg)
      const elements =
        data.reset_form == true
          ? [eventTarget]
          : document.querySelectorAll(idify(data.reset_form))
      elements.forEach((el) => el.closest('form')?.reset())
    }

    if (data.remove_clickover) {
      $('.clickover').remove()
    }

    if (data.highlight != null) {
      for (const id of Array.from(data.highlight)) {
        const selector = idify(id)
        const $el = $(`${selector}, ${selector} td`)

        if ($el.effect) $el.effect('highlight', {}, 5000)
        else
          $el.each((_, el) => {
            const oldTransition = el.style.transition
            const oldBgColor = el.style.backgroundColor
            const oldComputedBgColor =
              oldBgColor || getComputedStyle(el).backgroundColor
            el.style.backgroundColor = 'rgba(255, 255, 0, 0.6)'
            void el.offsetWidth
            el.style.transition = 'background-color 5s'
            el.style.backgroundColor = oldComputedBgColor
            setTimeout(() => {
              el.style.transition = oldTransition
              el.style.backgroundColor = oldBgColor
            }, 5000)
          })
      }
    }
    each_selector(data.highlight_by_selector, function () {
      this.effect('highlight', {}, 3000)
    })

    if (data.scroll_into_view != null) {
      const scrollTarget =
        data.scroll_into_view == true
          ? eventTarget
          : document.querySelector(idify(data.scroll_into_view))
      scrollIntoView(scrollTarget, {
        block: data.scroll_into_view_block || 'center',
        scrollMode: 'if-needed',
        behavior: 'smooth',
      })
    }

    if (data.modal != null) {
      showModal(data.modal)
    }

    if (data.clickover != null && eventTarget) {
      const $anchor = $(eventTarget)
      $anchor
        .clickover({
          title: data.clickover.title,
          content: data.clickover.content,
          html: true,
          container: $anchor.data('container') || 'body',
          placement: $anchor.data('placement') || 'bottom',
        })
        .data('clickover')
        .clickery()
    }

    if (data.reload != null) {
      window.location.reload()
    }

    if (data.click) {
      $(data.click).click()
    }

    if (data.post_message && window.parent) {
      window.parent.postMessage(data.post_message, '*')
    }

    if (data.redirect) {
      if (eventTarget && $.rails?.disableFormElements) {
        // We use jquery-ujs in admin. It disables form elements on submit and enables them once the request completes (even when doing ajax requests)
        // And is responsible for all the data-disable-with stuff.
        // However, for ajax requests, sometimes, we respond with a `redirect` directive to redirect the client to another page using javascript.
        // jquery-ujs is not aware of this and will instantly re-enable the form elements after the ajax request completes, even if it responsed with a redirect.
        // That we are now going to do with JS. This in many instances leads to a state where things appear broken, as if nothing happened.
        // This is a hack fix around that.
        setTimeout(() => {
          $.rails.disableFormElements($(eventTarget))
        }, 0)
      }

      if (data.redirect_method === 'post') {
        var add_form_input = function (form, name, value) {
          if (Array.isArray(value)) {
            for (let v of Array.from(value)) {
              add_form_input(form, `${name}[]`, v)
            }
          } else {
            const input = document.createElement('input')
            input.type = 'hidden'
            input.name = name
            input.value = value
            form.appendChild(input)
          }
        }

        const form = document.createElement('form')
        document.body.appendChild(form)
        form.method = 'post'
        form.action = data.redirect
        add_form_input(
          form,
          'authenticity_token',
          $('meta[name="csrf-token"]').attr('content')
        )
        for (let name in data.redirect_params) {
          add_form_input(form, name, data.redirect_params[name])
        }
        form.submit()
      } else {
        if (window.Turbolinks) {
          window.Turbolinks.visit(data.redirect)
        } else {
          window.location.href = data.redirect
        }

        if (data.notice != null) {
          setTimeout(() => showNoticeWithFireworks(data), 1000)
        }
      }
    }

    if (data.table_dnd_update != null) {
      for (const id of Array.from(data.table_dnd_update)) {
        $('#' + id).tableDnDUpdate()
      }
    }

    // Pass object with { id1: 'event1', id2: 'event2' }
    if (data.trigger != null) {
      for (const id in data.trigger) {
        const tevent = data.trigger[id]
        $(idify(id)).trigger(tevent)
      }
    }

    // Dispatches events using vanilla JS on the eventTarget (usually the element that initiated the ajax request)
    // You can either do dispatch_event: [event_1, event_2, event_3]
    // Or dispatch_event: { event_1: { event_1_details... }, event_2: { event_2_details... } }
    if (data.dispatch_event) {
      let events = data.dispatch_event

      // Normalize to be objects
      if (Array.isArray(events))
        events = Object.fromEntries(events.map((event) => [event, null]))

      for (const eventName in events) {
        let eventDetail = events[eventName]
        let target = $(eventTarget).data('portal') || eventTarget

        if (!target?.isConnected) target = document
        target.dispatchEvent(
          new CustomEvent(eventName, { detail: eventDetail, bubbles: true })
        )
      }
    }

    if (data.replace_query_params != null) {
      let url = new URL(window.location.href)
      url.search = data.replace_query_params

      window.history.pushState({}, '', url)
    }

    if (data.for_parent_frame && window.parent !== window) {
      const iframe = getIframeInParentDoc()
      iframe.ownerDocument.defaultView.AjaxHelper.doUpdate(
        data.for_parent_frame,
        iframe
      )
    }
  },

  error(message) {
    alert(window.I18n.t('general.error_processing', { message }))
  },

  successFunc(data) {
    AjaxHelper.doUpdate(data)
  },

  errorFunc(xhr, error, message) {
    AjaxHelper.error(message)
  },
}

window.AjaxHelper = AjaxHelper

$(document).on(
  'ajax:success',
  '[data-behavior~=ajax-update]',
  function (event, data) {
    if (this === event.target) {
      const finalData = data || event.originalEvent?.detail?.[0]
      AjaxHelper.doUpdate(finalData, this)

      // Dispatch CustomEvent to allow stimulus controllers to react to the ajax update
      event.target.dispatchEvent(
        new CustomEvent('ajax-update:complete', {
          detail: { data: finalData },
          bubbles: true,
        })
      )
    }
  }
)

$(document).on(
  'ajax:error',
  '[data-behavior~=ajax-update]',
  function (event, xhr, error, message) {
    if (this === event.target) {
      AjaxHelper.error(message)
    }
  }
)

$.sajax = function (options) {
  const callerSuccess = options.success

  options.success = function (data, status, xhr) {
    AjaxHelper.successFunc(data, status, xhr)
    if (callerSuccess) {
      callerSuccess(data, status, xhr)
    }
  }
  options.error = AjaxHelper.errorFunc
  if (options.type == null) {
    options.type = 'POST'
  }
  return $.ajax(options)
}
